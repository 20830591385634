<template>
    <div id="example-modal">
        <!-- Modal -->
        <div class="modal fade" ref="modal" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Admin</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form ref="account_form" @submit="submit" >
                            <b-form-group class="col-md-12 col-lg-12 col-xl-12 col-sm-12 b-form-group form-group">
                                <label>Name</label>
                                <b-form-input required name="name" v-model="name" autocomplete="username" placeholder="Name">
                                </b-form-input>
                                <div class="message">
                                    {{ validation.firstError("name") }}
                                </div>
                            </b-form-group>
                            <b-form-group class="col-md-12 col-lg-12 col-xl-12 col-sm-12 b-form-group form-group">
                                <label>Email</label>
                                <b-form-input required name="email" v-model="email" autocomplete="username" placeholder="Email">
                                </b-form-input>
                                <div class="message">
                                    {{ validation.firstError("email") }}
                                </div>
                            </b-form-group>
                            <b-form-group id="input-group-3" label="Roles" label-for="input-3">
                                <select required name="role_id" v-model="role_id" class="form-control mydrop">
                                    <option disabled value="" selected="selected">Please select one</option>
                                    <option v-for="role_id in this.rolesubmit()" :key="role_id.hash_id"  :value="role_id.hash_id">
                                        {{role_id.name}}
                                    </option>
                                </select>
                                <div class="message ml-3">
                                    {{ validation.firstError("role_id") }}
                                </div>
                            </b-form-group>
                            <div class="modal-footer">
                                <VueLoadingButton aria-label="Close" class="btn btn-secondary" data-dismiss="modal" :disabled="state.isSending">Close</VueLoadingButton>
                                <div>
                                    <div class="example">
                                        <VueLoadingButton aria-label="Post message" class="btn btn-primary" @click.native="submit" :loading="isLoading" :styled="!isStyled">Submit</VueLoadingButton>
                                    </div>


                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import Vue from "vue";
    import $ from "jquery";
    import VueLoadingButton from "vue-loading-button";
    import SimpleVueValidation from "simple-vue-validator";
    const Validator = SimpleVueValidation.Validator;
    Vue.use(SimpleVueValidation);
    export default {
        components : {
            name: 'addUserModal',
            VueLoadingButton
        },
        props : [ 'modelOpen','userRole'],

        data () {
            return {
                opened : false,
                name : '',
                email : '',
                role_id:'',
                getRole: [],
                link: '',
                isLoading: false,
                isStyled: false,
                users_erros: {
                    invalidEmail: false,
                    invalidphone_number: false
                },
                state: {
                    isSending: false
                },
                roles:[],
            }
        },
        validators: {
            name: function(value) {
                return Validator.value(value)
                    .required("Please enter name.");
            },
            email: function(value) {
                return Validator.value(value)
                    .required("Please enter the email.")
                    .email();
            },
            role_id: function(value) {
                return Validator.value(value)
                    .required("Please choose a role.")
            },

        },
        mounted() {
            this.opened = this.modelOpen
            if (this.opened == true) {
                let element = document.getElementById("exampleModal");
                $(element).modal('show')
            }
            this.rolesubmit();
        },
        methods: {
            rolesubmit() {
                return this.getRole= this.userRole;
            },
            submit() {
                var self = this;
                this.$validate().then(function(pass) {
                    if (pass === true) {
                        self.handleSubmit();
                    }
                });
            },
            handleSubmit (evt) {
                this.state.isSending = true;
                this.isLoading = true;
                let element = document.getElementById("exampleModal3");
                $(element).modal('show')
                let formData = new FormData ( this.$refs.account_form );
                formData.append ( "name", this.name );
                formData.append ( "email", this.email );
                formData.append ( "role_id", this.role_id);
                this.$store
                    .dispatch ( "addAdmin", formData)
                    .then (response => {
                        this.isLoading  = false;
                        this.state.isSending = false;
                        this.name='';
                        this.email='';
                        if ( response.data.error === false ) {
                            let element1 = document.getElementById("exampleModal3");
                            $(element1).modal('hide')
                            this.$notify("Success!", response.data.error_msg, "success");
                            this.link = response.data.data.link
                            this.$store.commit (
                                response.data
                            );
                        }
                    } )
                    .catch (error => {
                        this.isLoading  = false;
                        this.state.isSending = false;
                        if (error.response){
                            if (error.response.data.errors.email){
                                this.$notify("Error!", error.response.data.errors.email);
                            }
                            if (error.response.data.errors.phone_number){
                                this.$notify("Error!", error.response.data.errors.phone_number);
                            }
                            if (error.response.data.errors.name){
                                this.$notify("Error!", error.response.data.errors.name);
                            }
                        }
                        this.$store.commit ( 'custom/toggle_loader', false )
                    } );
                this.$refs.exampleModal.hide ();
                this.name = "";
                this.email = "";
                this.role_id = ""
            },
        },

    }

</script>

<style scoped>
    .break-link{
        word-break: break-all;
    }

    .copy-input {
        border: none;
        background: transparent;
        width: 92%;
        outline: none;
        pointer-events: none;
    }


    .icon-img {
        width: 16px;
        max-width: 100%;
        margin-left: 1.5%;
    }

    .errorfield {
        color: #ff0000;
        font-size: 14px;
        text-align: left;
        display: block;
        padding: 5px 0 0;
    }


    .message{
        color: #ff0000;
        font-size: 14px;
        text-align: left;
        display: block;
        padding: 5px 0 0;
    }

    .example {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*margin-top: 60px;*/
        text-align: center;
    }

</style>
<style>
    .mydrop
    {
        width: 94% !important;
        margin-left: 3% !important;
    }
    .d-block {
        margin-left: 3% !important;
    }
    .default-styles {
        padding: 5px 16.5px 5px 16px !important;
        background-color: #17a2b8 !important;
        border-color: #17a2b8 !important;
    }
</style>
