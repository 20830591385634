<template>
    <div>
        <button v-if="$store.state.permissions.includes('admin-store')" type="submit" class="btn btn-info float-right" @click="showModal" style="margin-right: 15px">Add Admin</button>
        <b-row class="ml-1 ">
            <h3>All Admins</h3>
        </b-row>
        <div class="mt-3 d-flex flex-wrap justify-content-between">
            <div class="ml-2">
                <label for="" class="mt-0 d-block font-weight-bold font-12">Records Per Page </label>
                <b-form-select class="hand width-select" :options="pageOptions" v-model="perPage"/>
            </div>

            <div class="ml-2">
                <div>
                    <label for="" class="mt-0 font-weight-bold font-12">Date range </label>
                    <div class="daterange d-flex" style="width:300px!important">
                        <div class="calender-range" ><i class="fa fa-calendar place"/></div>
                        <span class="daterange-dis ml-2"/>
                    </div>
                </div>
            </div>

            <div class="ml-2 ">
                <label for="" class="mt-0 font-weight-bold font-12">Search </label>
                <b-input-group>
                    <b-form-input v-model="search" placeholder="Type here"/>
                    <b-input-group-append>
                        <b-btn class="hand" :disabled="!search" @click="search = ''">Clear</b-btn>
                    </b-input-group-append>
                </b-input-group>
            </div>

        </div>

        <b-row class="mt-3" v-if="selected_company_id">
            <b-col>
                <b-table small responsive show-empty empty-text="No users found for this company"
                         striped
                         bordered hover fixed :items="this.adminlists" :fields="fields" :current-page="currentPage"
                         :per-page="perPage" :filter="search" @filtered="onFiltered" class="break">
                    <template v-slot:cell(is_active)="cell">
                        <!-- We use click.stop here to prevent a 'row-clicked' event from also happening -->
                        <span>
                            <i   v-if="$store.state.permissions.includes('admin-logs')" @click.stop="adminuserlog(cell.item.hash_id)" class="ml-2" size="sm" title="Admins Logs" v-b-tooltip.hover><i class="fa fa-user hand fa-style"></i></i>
                        </span>
                        <span>
                          <i v-if="$store.state.permissions.includes('admin-update')"
                              @click="editModel(cell.item)"
                              class="fa fa-pencil ml-3 hand"
                              title="Edit this admin"
                              v-b-tooltip.hover
                            />
                      </span>
                      <span>
                            <input  @click="changeActiveStatus(cell.item.hash_id, cell.item.is_active )" :title="cell.item.is_active == true ? 'Deactivate' : 'Activate'"
                              v-b-tooltip.hover class="ml-3 mt-1" type="checkbox" :id="cell.item.hash_id" name="archive_status" :checked="cell.item.is_active" >
                     </span>
                    </template>
                </b-table>
                <b-row>
                    <b-col md="12" class="my-1">
                        <b-pagination align="right" aria-controls="companies_table" :total-rows="totalRows"
                                      :per-page="perPage" v-model="currentPage"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <add-admin-modal :modelOpen="open" :userRole="role"/>
        <edit-admin-modal :modelOpen="open" :userRole="role" :adminName="admin_name"  :selectRole="editper" :adminHash="admin_hash_id"  ref="editdata"/>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import select2 from "../components/assets/select2";
    import moment from "moment";
    import $ from "jquery";
    import "daterangepicker/daterangepicker.js";
    import "daterangepicker/daterangepicker.css";
    import addAdminModal from "../components/AdminRole/addAdminModal";
    import editAdminModal from "../components/AdminRole/editAdminModal";

    export default {
        name: "Admins",
        components: {
            select2,
            addAdminModal,
            editAdminModal,
        },
        props : [ 'modelOpen','adminName','selectRole','userRole'],
        data() {
            return {
                active_status:false,
                editper:[],
                role: [],
                open: false,
                adminlists :[],
                admin_name:'',
                admin_hash_id:'',
                company_id: "all",
                selected_company_id: "all",
                start: moment().subtract(29, "days"),
                end: moment(),
                start_date: moment()
                    .subtract(29, "days")
                    .format("YYYY-MM-DD"),
                end_date: moment().format("YYYY-MM-DD"),
                fields: [
                    {
                    key:'name',
                        label: "Name",
                        sortable: true,
                        thStyle: {
                            width: "130px"
                        }
                    },
                     {
                        key :'email',
                        label: "Email",
                        sortable: true,
                        thStyle: {
                            width: "150px"
                        }
                    },
                     {
                         key:'is_active',
                        label: "Action",
                        thStyle: {
                            width: "70px"
                        }
                    }
                ],
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                search: null,
                totalRows: 0,
                filter: "all",
                isEmployee: true
            };
        },
        computed: {
            ...mapState(["admins"]),
            items() {
                let filteredAdmins =
                    this.filter === "all"
                        ? this.admins
                        : this.admins.filter(admins => admins.user_status === this.filter);
            },
            isBusy() {
                return this.$store.state.isBusy;
            },

        },
        watch: {
            selected_company_id: function(id) {
                let data = {
                    from: this.start_date,
                    to: this.end_date,
                    company_id: id
                };
                this.get_users(data);
            }
        },
        mounted() {
            this.totalRows = this.adminlists.length;
            this.start_date = null;
            this.end_date = null;
            this.dateRangeStart();
            this.getAdminData();
            this.getUserRoleList();
        },
        methods: {
            changeActiveStatus: function(hash_id, active_status) {
                let buttons = {
                    cancel : true,
                    confirm : {
                        text : "Change Active Status",
                        value : "active-status",
                        dangerMode : true,
                        closeModal : true
                    }
                };
                swal ( {
                    title : "",
                    text : "Are you sure you want to change active status?",
                    icon : "warning",
                    buttons : buttons,
                    dangerMode : true
                }).then ( willChangeStatus => {
                    if ( willChangeStatus === "active-status" )
                    {
                        this.$store.commit("toggle_loader", true);
                        let data = {
                            admin_id: hash_id,
                            is_active: !active_status   
                        };
                        this.$store
                            .dispatch("companyActiveStatus", data)
                            .then((response) => {
                                document.getElementById(hash_id).checked = !active_status
                                this.$store.commit("toggle_loader", false);
                                this.$notify ( "", response.data.error_msg, "success" );
                            })
                            .catch(error => {
                                this.$notify ( "", error.response.data.message, "danger" );
                                document.getElementById(hash_id).checked = active_status
                                this.$store.commit("toggle_loader", false);
                            });
                    }
                    else{
                        document.getElementById(hash_id).checked = active_status
                    }
                });
            },
            getUserRoleList() {
                this.$store
                    .dispatch("getUserRole")
                    .then(response => {
                        this.role = response.data.roles;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            detailModel(user) {
                this.$root.$emit("bv::show::modal", "detailsModel");
                this.$root.$emit("selected_user", user);
            },
            showModal() {
                this.open = true
                let element = document.getElementById("exampleModal3");
                $(element).modal('show')
            },
            adminuserlog(id) {
                let data = {
                    admin_id: id
                };
                this.$store.dispatch("getAdminUsersLogs", data);
                this.$router.push("/admins_logs");
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            get_users(company) {
                this.$store.dispatch("getUsers", company);
            },

            dateRangeStart() {
                $(".daterange span").html(
                    this.start.format("MMM D, YYYY") +
                    " - " +
                    this.end.format("MMM D, YYYY")
                );
                $(".daterange").daterangepicker(
                    {
                        startDate: this.start,
                        endDate: this.end,
                        opens: "left",
                        ranges: {
                            "Last 30 Days": [moment().subtract(29, "days"), moment()],
                            Today: [moment(), moment()],
                            Yesterday: [
                                moment().subtract(1, "days"),
                                moment().subtract(1, "days")
                            ],
                            "Last 7 Days": [moment().subtract(6, "days"), moment()],
                            "This Month": [moment().startOf("month"), moment().endOf("month")],
                            "Last Month": [
                                moment()
                                    .subtract(1, "month")
                                    .startOf("month"),
                                moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                            ],
                            "Last Year": [moment().subtract(1, "year"), moment()]
                        }
                    },
                    this.dateRangeChange
                );
            },
            dateRangeChange(start, end) {
                $(".daterange span")
                    .html(start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY"))
                    .addClass("pl-2 pr-1");
                this.start_date = start.format("YYYY-MM-DD");
                this.end_date = end.format("YYYY-MM-DD");

                this.getAdminData();
            },

            getAdminData()
            {
                this.$store.commit("toggle_loader", true);
                let data = {
                    from: this.start_date,
                    to: this.end_date,
                };
                this.$store.dispatch("getAdmins",data).then (response => {
                    this.$store.commit("toggle_loader", false);
                    this.adminlists= response.data.admins;
                    // console.log(response.data.admins[1].is_active)
                    // this.active_status = response.data.admins[1].is_active

                } )
                    .catch (error => {
                        console.log(error)
                    } );
            },
            editModel(admin) {
                let data = {
                    admin_id: admin.hash_id,
                }
                 this.admin_name = admin.name
                this.admin_hash_id = admin.hash_id
                this.$store.commit("toggle_loader", true);
                this.$store.dispatch("editAdmin",data)
                    .then(response => {
                        this.$store.commit("toggle_loader", false);
                        this.role = response.data.all_roles;
                        this.editper = response.data.selected_roles;
                        let perm = [];
                        this.editper.forEach(function(item){
                            perm.push(item.hash_id)
                        });
                        this.editper = perm;
                        this.$nextTick(() => {
                            this.$refs.editdata.isLoop();
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
                this.open = true;
                let element = document.getElementById("exampleModal4");
                $(element).modal("show");


            }


        }
    };
</script>

<style scoped>
.width-select{
    min-width: 130px!important;
}
    .daterange-dis {
        font-size: smaller;
    }

    .archive {
        margin-left: 10px;
    }
    .unarchive {
        margin-left: 10px;
    }
    .impersonate {
        margin-left: 10px;
    }

    .list {
        list-style-type: none;
        padding: 4px;
        box-shadow: 0 2px 4px 0 rgba(167, 161, 161, 0.5);
    }

    .image {
        max-height: 200px;
        min-height: 200px;
        max-width: 200px;
        min-width: 200px;
        border-radius: 3px;
    }
    .fa-style {
        font-size: 16px;
        padding-top: 3px;
    }
</style>

<style>
    .form-control {
        width: 73% !important;
    }
</style>
