<template>
    <div id="example-modal">
        <!-- Modal -->
        <div class="modal fade" ref="modal" id="exampleModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Admin</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div>
                    </div>
                    <div class="modal-body">
                        <form ref="account_form" @submit="submit" >
                            <b-form-group class="col-md-12 col-lg-12 col-xl-12 col-sm-12 b-form-group form-group">
                                <label>Name</label>
                                <b-form-input required  name="name" v-model="this.getname()" autocomplete="username" placeholder="Name" readonly>
                                </b-form-input>
                                <!--                            <div class="message">-->
                                <!--                                {{ validation.firstError("name") }}-->
                                <!--                            </div>-->
                            </b-form-group>
                            <b-form-group id="input-group-3" label="Roles" label-for="input-3">
                                <select v-model="role_id" class="form-control mydrop">
                                    <option disabled value="" selected="selected">Please select one</option>
                                    <option v-for="role_id in this.rolesubmit()" :key="role_id.hash_id"  :value="role_id.hash_id" >
                                        {{role_id.name}}
                                    </option>
                                </select>
                            </b-form-group>
                            <div class="modal-footer">
                                <VueLoadingButton aria-label="Close" class="btn btn-secondary" data-dismiss="modal" :disabled="state.isSending">Close</VueLoadingButton>
                                <div>
                                    <div class="example">
                                        <VueLoadingButton aria-label="Post message" class="btn btn-primary" @click.native="submit" :loading="isLoading" :styled="!isStyled">Submit</VueLoadingButton>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from "vue";
    import $ from "jquery";
    import VueLoadingButton from "vue-loading-button";
    import SimpleVueValidation from "simple-vue-validator";
    const Validator = SimpleVueValidation.Validator;
    Vue.use(SimpleVueValidation);
    export default {
        components: {
            name: "editAdminModal",
            VueLoadingButton
        },
        props: ["modelOpen","adminName","selectRole","userRole","adminHash"],

        data() {
            return {
                role_id:'',
                getRole: [],
                opened: false,
                getUserName:'',
                getAdminHash_id:'',
                name: "",
                isLoading: false,
                isStyled: false,
                users_erros: {
                    invalidEmail: false,
                    invalidphone_number: false
                },
                state: {
                    isSending: false
                }
            };
        },

        validators: {
            name: function(value) {
                return Validator.value(value).required("Name is required");
            }
        },
        mounted() {
            this.opened = this.modelOpen;
            if (this.opened == true) {
                let element = document.getElementById("exampleModal");
                $(element).modal("show");
            }
            this.getname();
            this.rolesubmit();
        },

        methods: {
            isLoop()
            {
                this.role_id = this.selectRole[0];
            },
            rolesubmit() {
                return this.getRole= this.userRole;
            },
            getname()
            {
                return this.getUserName = this.adminName;
            },
            get_admin_hash_id()
            {
                return this.getAdminHash_id = this.adminHash;
            },
            submit() {
                this.handleSubmit();
            },
            handleSubmit(evt) {
                this.state.isSending = true;
                this.isLoading = true;
                let element = document.getElementById("exampleModal4");
                $(element).modal("show");
                let data = {
                    admin_id:this.get_admin_hash_id(),
                    role_id:this.role_id,

                };
                this.$store.dispatch("updateAdmin", data)
                    .then(response => {
                        this.isLoading = false;
                        this.state.isSending = false;
                        this.name='';
                        this.role_id = '';
                        if (response.data.error === false) {
                            let element1 = document.getElementById("exampleModal4");
                            $(element1).modal("hide");
                            this.$notify("Success!", response.data.error_msg, "success");
                            this.$store.commit(
                                response.data
                            );
                        }
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.state.isSending = false;
                        if (error.response) {
                            if (error.response.data.errors.email) {
                                this.$notify("Error!", error.response.data.errors.email);
                            }
                            if (error.response.data.errors.phone_number) {
                                this.$notify("Error!", error.response.data.errors.phone_number);
                            }
                            if (error.response.data.errors.name) {
                                this.$notify("Error!", error.response.data.errors.name);
                            }
                        }

                        this.$store.commit("custom/toggle_loader", false);
                    });
                this.$refs.exampleModal.hide();
            }
        }
    };
</script>

<style scoped>
    .message {
        color: #ff0000;
        font-size: 14px;
        text-align: left;
        display: block;
        padding: 5px 0 0;
    }
    .p-text{
        margin-left: 2%;
    }
</style>
<style>
    li {
        display: block !important;
    }
    .form-control
    {
        width: 50% !important;
    }
    .card
    {
        width: 90% !important;
        margin-left: 2% !important;
    }
    .modal-footer
    {
        border-top: 1px solid #dee2e600 !important;
        margin-top: 2% !important;
    }
    .default-styles {
        padding: 5px 16.5px 5px 16px !important;
        background-color: #17a2b8 !important;
        border-color: #17a2b8 !important;
    }
</style>
